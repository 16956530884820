/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import SkeletonStyles from './SkeletonStyles';

const styles = () => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    root: {},
    hero: {
        width: '100%',
        height: '400px',
    },

    findAGiftFastContainer: {
        maxWidth: '400px',
    },

    productSiloContainer: {
        maxWidth: '1400px',
    },
    productSiloWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100px',
    },
    productSiloImg: {
        width: '200px',
        height: '200px',
    },
    categoryBlocksContainer: {
        padding: '20px 0',
        width: '100%',
        height: 'auto',
    },
    categoryBlocksWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '300px 300px',
        gridGap: '10px',
        margin: '0 auto',
        maxWidth: '610px',
    },
    categoryBlock: {
        position: 'relative',
        padding: '20px 0',
        width: '100%',
        height: 'auto',
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.06)',
    },
    cetegoryBlockTitle: {
        position: 'absolute',
        left: '0',
        bottom: '0',
        padding: '10px 10px',
        width: '100%',
        background: '#fff',
    },
    removeMargin: {
        margin: 0,
    },
});

const HomePageSkeleton = ({
    classes,
    classes: {
        center,
        container,
        divider,
        shimmer,
        textSM,
        textMD,
        textLG,
        wrapper,
    },
}) => (
    <div className={classes.root}>
        <div className={`${classes.hero} ${shimmer}`} />
        <div className={`${wrapper} ${divider}`}>
            <div className={`${classes.findAGiftFastContainer} ${container}`}>
                <div>
                    <div className={`${textSM} ${shimmer}`} />
                    <div className={`${textMD} ${shimmer}`} />
                </div>
                <div>
                    <div className={`${textSM} ${shimmer}`} />
                    <div className={`${textMD} ${shimmer}`} />
                </div>
                <div>
                    <div className={`${textSM} ${shimmer}`} />
                    <div className={`${textMD} ${shimmer}`} />
                </div>
            </div>
        </div>
        <div className={wrapper}>
            <div className={`${textLG} ${shimmer} ${center}`} />
        </div>
        <div className={wrapper}>
            <div className={`${classes.productSiloContainer} ${container}`}>
                <div className={classes.productSiloWrapper}>
                    <div className={`${shimmer} ${classes.productSiloImg}`} />
                    <div className={`${textSM} ${shimmer}`} />
                </div>
                <div className={classes.productSiloWrapper}>
                    <div className={`${shimmer} ${classes.productSiloImg}`} />
                    <div className={`${textSM} ${shimmer}`} />
                </div>
                <div className={classes.productSiloWrapper}>
                    <div className={`${shimmer} ${classes.productSiloImg}`} />
                    <div className={`${textSM} ${shimmer}`} />
                </div>
                <div className={classes.productSiloWrapper}>
                    <div className={`${shimmer} ${classes.productSiloImg}`} />
                    <div className={`${textSM} ${shimmer}`} />
                </div>
                <div className={classes.productSiloWrapper}>
                    <div className={`${shimmer} ${classes.productSiloImg}`} />
                    <div className={`${textSM} ${shimmer}`} />
                </div>
                <div className={classes.productSiloWrapper}>
                    <div className={`${shimmer} ${classes.productSiloImg}`} />
                    <div className={`${textSM} ${shimmer}`} />
                </div>
            </div>
        </div>
        <div className={`${classes.categoryBlocksContainer}`}>
            <div className={classes.categoryBlocksWrapper}>
                <div className={`${classes.categoryBlock} ${shimmer}`}>
                    <div className={classes.cetegoryBlockTitle}>
                        <div className={`${classes.removeMargin} ${shimmer}`} />
                    </div>
                </div>
                <div className={`${classes.categoryBlock} ${shimmer}`}>
                    <div className={classes.cetegoryBlockTitle}>
                        <div className={`${classes.removeMargin} ${shimmer}`} />
                    </div>
                </div>
                <div className={`${classes.categoryBlock} ${shimmer}`}>
                    <div className={classes.cetegoryBlockTitle}>
                        <div className={`${classes.removeMargin} ${shimmer}`} />
                    </div>
                </div>
                <div className={`${classes.categoryBlock} ${shimmer}`}>
                    <div className={classes.cetegoryBlockTitle}>
                        <div className={`${classes.removeMargin} ${shimmer}`} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

HomePageSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(HomePageSkeleton);
